/* .InovuaReactDataGrid__column-header__content {
  white-space: normal !important;
} */

/* .InovuaReactDataGrid__cell__content {
  white-space: normal !important;
} */

.InovuaReactDataGrid__cell {
  overflow: hidden !important;
}

.InovuaReactDataGrid__group-toolbar--theme-default-light {
  display: none;
}

.InovuaReactDataGrid__column-resizer,
.InovuaReactDataGrid__header-group-resizer {
  display: none;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__header-group__title {
  white-space: pre-wrap !important;
  height: 100%;
  text-align: center;
}

/* .InovuaReactDataGrid__column-header__content.InovuaReactDataGrid__box--ellipsis {
  display: none;
} */

.react-grid-item.react-grid-placeholder {
  background-color: grey;
}

.normalFontWeight {
  font-weight: 400;
}

.boldFontWeight {
  font-weight: 700;
}

.startTextAlign {
  text-align: left;
}

.centerTextAlign {
  text-align: center;
}

.endTextAlign {
  text-align: right;
}

.kstk-dropdown-menu {
  min-width: 4rem;
}
