// $gray-800: #48454e;
$blue: #3a62ac;
$teal: #4baca8;
$cyan: #4baca8;
$red: #e4543c;
$yellow: #eac740;
$white: #ffffff;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

/* $dropdown-link-color: $white;
$dropdown-link-hover-color: darken($white, 5%);
$dropdown-link-hover-bg: $gray-900; */

// .nav-tabs .nav-link.active.dark:hover {
//   color: $gray-800;
// }

//   $dropdown-link-active-color: $component-active-color !default;
//   $dropdown-link-active-bg: $component-active-bg !default;

//   $dropdown-link-disabled-color: $gray-600 !default;

//   $dropdown-item-padding-y: 0.25rem !default;
//   $dropdown-item-padding-x: 1.5rem !default;

//   $dropdown-header-color: $gray-600 !default;

@import "~bootstrap/scss/bootstrap";
$primary: $blue;
$dark: $gray-700;
