.react-grid-Main {
  height: 100%;
  outline: none !important;
}

.react-grid-Container {
  height: 100%;
  font-size: 0.7em !important;
}

.react-grid-Grid {
  min-height: 100% !important;
}

.react-grid-Canvas {
  height: 100% !important;
}
